// Note, this is a temporary version until SSE is available

// Library Imports
import React, { useEffect, useRef, useState, useContext } from "react"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import { Button, ClickAwayListener, IconButton } from "@mui/material"
import {
  AttachFile as AttachFileIcon,
  Close as CloseIcon,
  ScienceOutlined as ScienceOutlinedIcon,
} from "@mui/icons-material"

// Component Imports
import { ErrorContext } from "../../helper/AlertContext"
import TextArea from "../items/TextArea"
import JsonSnippet from "../items/JsonSnippet"
import UploadFileDialog from "../items/UploadFileDialog"
import { accountService, datasetService } from "../../api/services"
import { formatJsonString } from "../../utils"
import { FILE_TYPE_MAPPING } from "../../utils/constants"

// Stylesheet Imports
import "../../styles/TestWindow.css"
import "../../styles/Code.css"

const MAXIMUM_FILE_AMOUNT = 1

const TestWindow = ({ appliData, viewLog, customVariables, apiView, showUploadIcon }) => {
  const [fileList, setFileList] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [inputs, setInputs] = useState({})
  const [isScrolled, setIsScrolled] = useState(false)
  const [inputDisabled, setInputDisabled] = useState(false)
  const [hideWelcome, setHideWelcome] = useState(false)
  const [messages, setMessages] = useState([])
  const [prompt, setPrompt] = useState("")
  const [showUploadDialog, setShowUploadDialog] = useState(false)
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const messagesContainerRef = useRef(null)
  const abortControllerRef = useRef(null)
  const postUrl = appliData.app.http_url.replace("/$(channel_token)", "")
  let eventSrc

  // Handle message retrieval
  const fetchResponse = async (appId, payload, inputs) => {
    abortControllerRef.current = new AbortController()
    const isEmptyInputs = (inputs) => Object.values(inputs).every((value) => value === "")
    const requestPayload = {
      payload: payload,
      custom_variables: isEmptyInputs(inputs) ? null : JSON.parse(inputs),
      app_id: appId,
      ...(fileList?.length && { input_files: fileList }),
    }

    try {
      const response = await fetch(`${postUrl}/playground`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestPayload),
        signal: abortControllerRef.current.signal,
      })

      // Check if the fetch has been aborted after receiving the response
      if (abortControllerRef.current.signal.aborted) {
        console.log("Fetch was aborted.")
        return
      }

      const data = await response.json()

      if (response.ok) {
        setMessages((prev) => {
          const lastMsg = prev[prev.length - 1]
          const uniqueFiles =
            data.citation && data.citation.file ? [...new Set(data.citation.file.split(","))].join(", ") : ""
          const updatedMessage = {
            type: "received",
            content: JSON.stringify(data) || "",
            citation: uniqueFiles,
            blink: false,
            showImage: false,
          }

          if (lastMsg && lastMsg.type === "received") {
            return prev.map((msg, index) => (index === prev.length - 1 ? updatedMessage : msg))
          } else {
            return [...prev, updatedMessage]
          }
        })
        accountService.updateTaskList({ playground: true })
      } else {
        if (response.status === 443) {
          throw new Error("The project is currently disabled. Please enable it to proceed with queries.")
        } else if (response.status === 444) {
          throw new Error("Your data is not ready, please try again later.")
        } else {
          throw new Error(data.text)
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted by the user.")
      } else {
        setError(true)
        setErrorMsg(error.message || "Error occurred; please try again.")
        setMessages((prev) => {
          prev[prev.length - 1] = {
            type: "received",
            blink: false,
            content: `Error: ${error.message}` || "Error occurred; please try again.",
            showImage: false,
          }
          return prev
        })
      }
    } finally {
      setInputDisabled(false)
    }
  }

  const handleInputChange = (key, value) => {
    setInputs((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  const handleFetchUrl = async (url) => {
    if (fileList.length >= MAXIMUM_FILE_AMOUNT) {
      setError(true)
      setErrorMsg(`You can only add up to ${MAXIMUM_FILE_AMOUNT} file.`)
    } else {
      setFileList((prevValue) => [
        ...prevValue,
        { remote_url: url, name: url, id: new Date().getTime(), content_type: "" },
      ])
      setShowUploadDialog(false)
    }
  }

  const handleFileChange = async (event) => {
    const files = event.target.files

    if (files) {
      const formData = new FormData()

      Array.from(files).forEach((file) => {
        formData.append("files", file)
        formData.append("is_public", "true")
      })
      if (fileList.length + files.length > MAXIMUM_FILE_AMOUNT) {
        setError(true)
        setErrorMsg(`You can only add up to ${MAXIMUM_FILE_AMOUNT} file.`)
        return
      }
      setIsUploading(true)
      try {
        const { data } = await datasetService.cacheUploadFiles(formData)

        setFileList((prevValue) => [...prevValue, ...data])
        setShowUploadDialog(false)
      } catch (error) {
        setError(true)
        setErrorMsg("Upload failed.")
      } finally {
        setIsUploading(false)
      }
    }
  }

  const removeFile = (id) => {
    setFileList((prevValue) => prevValue.filter((file) => file.id !== id))
  }

  // Handle send messages
  const sendMessage = async (event) => {
    if (event) {
      event.preventDefault()
    }

    // Check if source is data source, and the source status, block if none ready
    if (appliData.dataframe?.sources?.length) {
      if (appliData.dataframe.sources.every((source) => source.status !== "r")) {
        setMessages((prev) => [
          ...prev,
          {
            type: "received",
            content: "Your data is not ready, please try again later.",
          },
        ])
        setPrompt("")
        setIsScrolled(true)
        setHideWelcome(true)
        return
      }
    }

    const customInputs = JSON.stringify(inputs)
    const combinedObject = {
      payload: prompt,
      ...(inputs && Object.keys(inputs).length > 0 && { custom_variables: inputs }),
    }
    const updatedMessage = JSON.stringify(combinedObject)
    const resetInputs = Object.keys(customVariables).reduce((acc, key) => {
      acc[key] = ""
      return acc
    }, {})
    if (prompt) {
      setInputDisabled(true)
      setMessages((prev) => [...prev, { type: "sent", content: updatedMessage }])
      setPrompt("")
      setInputs(resetInputs)
      setHideWelcome(true)

      setMessages((prev) => [
        ...prev,
        { type: "received", content: `{"playground": "Generating response..."}`, blink: false, showImage: true },
      ])
      fetchResponse(appliData.app.id, prompt, customInputs)
    }
    setIsScrolled(true)
  }

  // Handle user click stop generation
  const abortFetch = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()

      // Find the "Generating response..." message and replace its content with "Generation Stopped"
      setMessages((prev) =>
        prev.map((msg) =>
          msg.content === `{"playground": "Generating response..."}`
            ? { ...msg, content: `{"playground": "Response generation aborted..."}`, showImage: false }
            : msg,
        ),
      )
    }
  }

  const extractValue = (contentString, keys = ["payload", "text", "playground"]) => {
    let content = null

    try {
      content = JSON.parse(contentString)
    } catch (e) {
      console.error("Failed to parse content string:", e)
      return null
    }
    if (content && typeof content === "object") {
      for (const key of keys) {
        if (Object.prototype.hasOwnProperty.call(content, key)) {
          if (key === "text" && typeof content[key] === "object") {
            return JSON.stringify(content[key])
          } else {
            return content[key]
          }
        }
      }
    }
    return null
  }

  useEffect(() => {
    const initialInputs = Object.keys(customVariables).reduce((acc, key) => {
      acc[key] = ""
      return acc
    }, {})

    setInputs(initialInputs)
  }, [customVariables])

  // Update scroll position whenever messages change
  useEffect(() => {
    if (messagesContainerRef.current && isScrolled) {
      const element = messagesContainerRef.current
      element.scrollTop = element.scrollHeight
    }

    return () => {
      if (eventSrc) {
        eventSrc.close()
      }
    }
  }, [messages, isScrolled])

  return (
    <div className="chat-window">
      <div className="messages" ref={messagesContainerRef}>
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.type} ${apiView ? "apiView" : "chatView"}`}>
            <div id="text-container" className={message.blink ? "blink" : ""}>
              <div
                className="message-text"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                {!apiView ? (
                  <div style={{ padding: "20px", backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      components={{
                        code({ inline, className, children, ...props }) {
                          const match = /language-(\w+)/.exec(className || "")
                          return !inline && match ? (
                            <pre className={`language-${match[1]}`}>
                              <code className={className} {...props}>
                                {children}
                              </code>
                            </pre>
                          ) : (
                            <code className={className} {...props}>
                              {children}
                            </code>
                          )
                        },
                        p: ({ node, ...props }) => {
                          if (node.children && node.children.length === 1 && node.children[0].type === "text") {
                            return <>{props.children}</>
                          } else {
                            return <p {...props} />
                          }
                        },
                      }}
                    >
                      {extractValue(message.content)}
                    </ReactMarkdown>
                  </div>
                ) : (
                  <JsonSnippet>{formatJsonString(message.content)}</JsonSnippet>
                )}
                {message.citation ? (
                  <>
                    <p className="citation-head">Searched Sources:</p>
                    <div className="citation-content">
                      {message.citation.split(",").map((file, fileIndex) => (
                        <span key={fileIndex} className="citation-item">
                          {file.trim()}
                        </span>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        ))}
        {!hideWelcome && (
          <div style={{ margin: "auto", textAlign: "center", width: "25rem" }}>
            <ScienceOutlinedIcon style={{ fontSize: "3rem" }} /> <h3>Vext Playground</h3>
            <span>Playground project version: v2</span>
            <p style={{ display: "inline-block", paddingTop: 20 }}>
              Type something to get started. Note that all response shown here is what your users will see.
            </p>
          </div>
        )}
      </div>
      <div className="file-container">
        {fileList.map((file) => (
          <div key={file.id} className="file-card">
            <div className="icon">
              <AttachFileIcon />
            </div>
            <div>
              <strong>{file.name}</strong>
              <div>{FILE_TYPE_MAPPING[file.content_type] || file.content_type}</div>
            </div>
            <IconButton size="small" className="btn-close" onClick={() => removeFile(file.id)}>
              <CloseIcon />
            </IconButton>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: Object.keys(inputs).length === 0 ? "row" : "column",
          gap: "0.5rem",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "0.5rem" }}>
          <TextArea
            value={prompt}
            onChange={(newValue) => setPrompt(newValue)}
            onPressEnter={sendMessage}
            placeholder="Type something..."
            mLength={2000}
            disabled={inputDisabled}
            startAdornment={
              showUploadIcon ? (
                <div
                  style={{ borderRight: "1px #ccc solid", marginRight: 10.4, paddingRight: 10.4, position: "relative" }}
                >
                  {showUploadDialog && (
                    <ClickAwayListener onClickAway={() => setShowUploadDialog(false)}>
                      <div>
                        <UploadFileDialog
                          isLoading={isUploading}
                          onFetchUrl={handleFetchUrl}
                          onUpload={handleFileChange}
                        />
                      </div>
                    </ClickAwayListener>
                  )}
                  <IconButton
                    onClick={() => {
                      setShowUploadDialog(true)
                    }}
                    size="small"
                    style={{ transform: "rotate(90deg)" }}
                  >
                    <AttachFileIcon />
                  </IconButton>
                </div>
              ) : null
            }
          />
          {Object.keys(inputs).map((key) => (
            <TextArea
              key={key}
              value={inputs[key]}
              onChange={(e) => handleInputChange(key, e)}
              placeholder={`${key} (Optional)`}
              mLength={200}
              disabled={inputDisabled}
            />
          ))}
        </div>
        <div style={{ display: "flex", gap: "0.5rem", justifyContent: "flex-end", whiteSpace: "nowrap" }}>
          {inputDisabled && (
            <Button variant="outlined" onClick={abortFetch}>
              Stop Generation
            </Button>
          )}
          <Button variant="contained" onClick={sendMessage}>
            Send
          </Button>
          {hideWelcome && (
            <Button variant="outlined" onClick={viewLog} style={{ whiteSpace: "nowrap" }}>
              See Log
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default TestWindow
