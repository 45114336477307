// Library Imports
import React, { useContext, useEffect, useState } from "react"
import { Box, Button, Skeleton, Tab, Tabs } from "@mui/material"
import { Launch as LaunchIcon } from "@mui/icons-material"

// Component Imports
import { ErrorContext, SuccessContext } from "../../helper/AlertContext"
import { AccountContext } from "../../helper/AccountContext"
import useStripeSession from "../../helper/StripePortal"
import InputField from "../items/InputField"
import InputDialog from "../items/InputDialog"
import LinearProgress from "../items/Progress"
import BasicTooltip from "../items/BasicTooltip"
import MemberRow from "../items/MemberRow"
import MessageBox from "../items/MessageBox"
import WorkspaceRow from "../items/WorkspaceRow"
import ConfirmationDialog from "../items/ConfirmationDialog"
import CreateMemberForm from "./CreateMemberForm"
import TabPanel, { a11yProps } from "../template/TabPanel"
import { accountService, organizationService } from "../../api/services"

import { ROLES, CUSTOM_ERR_MSG } from "../../utils/constants"

import AccountTabsStyles from "../../styles/AccountTabs.module.css"

export default function AccountTabs() {
  const [value, setValue] = useState(0)
  const [availableCredit, setAvailableCredit] = useState(99999)
  const [creatingMemberForm, setCreatingMemberForm] = useState({})
  const [planLogo, setPlanLogo] = useState("")
  const [memberList, setMemberList] = useState([])
  const [workspaceList, setWorkspaceList] = useState([{ id: 1, name: "Project 1", credit_limit: 50000 }]) // mock data
  const [deletingUser, setDeletingUser] = useState({})
  const [deletingWorkspace, setDeletingWorkspace] = useState({})
  const [dialogState, setDialogState] = useState({ show: false, type: "" })
  const [isLocalLoading, setIsLocalLoading] = useState(false)
  const [isLoadingCard, setIsLoadingCard] = useState(false)
  const [isLoadingTable, setIsLoadingTable] = useState(false)
  const [isCreatingButtonDisabled, setIsCreatingButtonDisabled] = useState(false)
  const [isPerson, setIsPerson] = useState(true)
  const [roleOptions, setRoleOptions] = useState([])
  const [profile, setProfile] = useState({})
  const [currentOrganization, setCurrentOrganization] = useState({})
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const { setSuccess, setSuccessMsg } = useContext(SuccessContext)
  const { accountInfo, fetchAccountStatus, setSubPrompt } = useContext(AccountContext)

  const { loading, createStripeSession } = useStripeSession()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleFormChange = (payload) => {
    setCreatingMemberForm(payload)
  }
  const handleCreateMember = async () => {
    let receivers = creatingMemberForm.email.split(",")
    let modified_error = ""
    const roles_id = new Array(receivers.length).fill(creatingMemberForm.role)

    receivers = receivers.map((r) => r.trim())
    setIsLocalLoading(true)
    try {
      await organizationService.sendInvitationEmail({ receivers, roles_id })
      setSuccess(true)
      setSuccessMsg("Invitation email has sent.")
      toggleDialog()
      handleGetMemberList()
    } catch (error) {
      if (error.response.data.text === "organization for basic type more than one") {
        modified_error = CUSTOM_ERR_MSG.org_invite_more_than_one
      }
      setError(true)
      setErrorMsg(modified_error || error.message)
    } finally {
      setIsLocalLoading(false)
    }
  }
  const toggleDialog = (type = "") => {
    const { show } = dialogState
    setDialogState({ show: !show, type })
  }
  const handleGetMemberList = async () => {
    try {
      setIsLoadingTable(true)
      const { data } = await organizationService.getMemberList()
      const userList = []

      data.data.map((item) => {
        userList.push({
          id: item.id,
          name: `${item.first_name} ${item.last_name}`,
          email: item.email,
          status: item.role_status.status,
          role_id: item.role_status.id,
        })
      })
      setMemberList(userList)
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLoadingTable(false)
    }
  }
  const handleGetRoleList = async (orgId) => {
    try {
      const { data } = await organizationService.getRoleList(orgId)
      const options = []
      const roleMapping = {
        admin: "Admin",
        editor: "Editor",
        read: "Viewer",
      }

      ROLES.map((targetRole) => {
        data.roles.map((role) => {
          if (targetRole === role.group_name) {
            options.push({ label: roleMapping[targetRole], value: role.id })
          }
        })
      })
      setRoleOptions(options)
    } catch (error) {
      setError(true)
      setErrorMsg(CUSTOM_ERR_MSG.account_get_role_failed)
    }
  }
  const handleReinvite = async (email, role) => {
    let modified_error = ""

    try {
      await organizationService.sendInvitationEmail({ receivers: [email], roles_id: [role] })
      setSuccess(true)
      setSuccessMsg("Invitation email has sent.")
    } catch (error) {
      if (error.message === "organization for basic type more than one") {
        modified_error = CUSTOM_ERR_MSG.org_invite_more_than_one
      } else {
        modified_error = error.message
      }
      setError(true)
      setErrorMsg(modified_error)
    }
  }
  const handleCreateWorkspace = async (payload) => {
    setIsLocalLoading(true)
    try {
      // TODO: request create workspace
      toggleDialog()
    } catch (error) {
      setError(true)
      setErrorMsg(error.response?.data?.message)
    } finally {
      setIsLocalLoading(false)
    }
  }
  const handleEditCreditLimit = async (payload) => {
    setIsLocalLoading(true)
    try {
      // TODO: request edit credit limit
      toggleDialog()
    } catch (error) {
      setError(true)
      setErrorMsg(error.response?.data?.message)
    } finally {
      setIsLocalLoading(false)
    }
  }
  const handleDeleteUser = async () => {
    setIsLocalLoading(true)
    try {
      await organizationService.removeMember(deletingUser.id)
      setDeletingUser({})
      toggleDialog()
      handleGetMemberList()
    } catch (error) {
      setError(true)
      if (error.response.status === 403) {
        setErrorMsg(error.message)
      } else {
        setErrorMsg(error.response?.data?.message)
      }
    } finally {
      setIsLocalLoading(false)
    }
  }
  const onClickRemoveUser = (id) => {
    setDeletingUser({ id })
    toggleDialog("removeMember")
  }
  const onClickRemoveWorkspace = (id) => {
    setDeletingWorkspace({ id })
    toggleDialog("removeWorkspace")
  }
  const fetchAccount = async () => {
    setIsLoadingCard(true)
    try {
      const { config, data } = await accountService.getAccount()
      const orgId = config.headers["Org-Id"]

      if (orgId) {
        setProfile({ ...data, org_id: orgId })
        fetchAccountStatus((account) => {
          const targetOrg = account.organizations.find((org) => org.id === orgId)
          const isPersonalOrg = account.personalOrganization.id === orgId

          setIsPerson(isPersonalOrg)
          setCurrentOrganization(targetOrg)
          if (!isPersonalOrg) {
            handleGetMemberList()
            handleGetRoleList(orgId)
          }
        })
      }
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLoadingCard(false)
    }
  }

  useEffect(() => {
    if (["Starter", "Pro", "Enterprise"].includes(accountInfo.subscriptionPlan)) {
      setPlanLogo(`/images/${accountInfo.subscriptionPlanRaw}_plan.svg`)
    } else {
      setPlanLogo(`/images/free_plan.svg`)
    }
  }, [accountInfo])
  useEffect(() => {
    setIsCreatingButtonDisabled(Object.values(creatingMemberForm).some((value) => !value))
  }, [creatingMemberForm])
  useEffect(() => {
    fetchAccount()
  }, [])

  return (
    <>
      {isLoadingCard || !currentOrganization.type ? (
        <Box className={AccountTabsStyles.accountContainer} style={{ height: 335 }}>
          <div style={{ height: 48 }}></div>
          <div className={AccountTabsStyles.loadingCard}>
            <Skeleton variant="rounded" animation="wave" height={20} width={100} />
            <Skeleton variant="rounded" animation="wave" height={16} width="30%" />
            <Skeleton variant="rounded" animation="wave" height={20} width={120} />
            <Skeleton variant="rounded" animation="wave" height={40} />
            <Skeleton variant="rounded" animation="wave" height={20} width={120} />
            <Skeleton variant="rounded" animation="wave" height={40} />
          </div>
        </Box>
      ) : (
        <Box className={AccountTabsStyles.accountContainer}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="Account Information">
              <Tab label="Profile" {...a11yProps(0)} />
              <Tab label="Usage & Billing" {...a11yProps(1)} />
              {!isPerson && <Tab label="Members" {...a11yProps(2)} />}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className={AccountTabsStyles.tabPanel}>
              <div style={{ paddingBottom: "1rem" }}>
                <h4>Organization</h4>
                <p>
                  {currentOrganization.type === "p" ? "Personal" : currentOrganization.name}
                  {currentOrganization.type !== "p" && currentOrganization.id && (
                    <span style={{ fontStyle: "italic", color: "#3d3d3d80", fontSize: "0.7rem" }}>
                      {`(Organization ID: ${currentOrganization.id})`}
                    </span>
                  )}
                </p>
                <h4>Full Name</h4>
                <InputField id="userName" value={profile.first_name + " " + profile.last_name} disabled={true} />
                <h4>Email</h4>
                <InputField id="userEmail" value={profile.email} disabled={true} />
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className={AccountTabsStyles.tabPanel}>
              <h4>Plan</h4>
              <div className={AccountTabsStyles.plan}>
                <div style={{ display: "flex", gap: "1rem", alignItems: "center", marginBottom: "1rem" }}>
                  <img style={{ width: "2.5rem", height: "auto" }} src={planLogo} alt={`plan logo`} />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p style={{ fontWeight: "700", marginBottom: "0.3rem" }}>{accountInfo.subscriptionPlan} Plan</p>
                    {accountInfo.subscriptionPlan === "Free" ? null : (
                      <>
                        <p style={{ fontSize: "0.7rem" }}>
                          Start Date:{" "}
                          {new Date(accountInfo.subscriptionStart * 1000).toLocaleString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </p>
                        <p style={{ fontSize: "0.7rem" }}>
                          End Date:{" "}
                          {new Date(accountInfo.subscriptionEnd * 1000).toLocaleString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </p>
                      </>
                    )}
                  </div>
                </div>
                <Button
                  style={{ alignSelf: "flex-start" }}
                  variant="contained"
                  endIcon={!loading ? <LaunchIcon style={{ fontSize: "1rem" }} /> : ""}
                  onClick={createStripeSession}
                  disabled={loading}
                >
                  {!loading ? "Billing & Subscription" : "Redirecting..."}
                </Button>
              </div>
              {["Free", "Starter"].includes(accountInfo.subscriptionPlan) ? (
                <MessageBox
                  type="bolt"
                  message={
                    <React.Fragment>
                      {`Upgrade plan to enable more powerful features for your LLM pipeline. `}
                      <span
                        style={{ cursor: "pointer", textDecoration: "underline" }}
                        onClick={() => setSubPrompt(true)}
                      >
                        Upgrade plan
                      </span>
                    </React.Fragment>
                  }
                />
              ) : null}
              <h4 style={{ marginTop: 8 }}>Usage</h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",
                  padding: "1rem",
                }}
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ marginBottom: "1rem" }}>
                      Credit Usage
                      <BasicTooltip
                        tooltip={
                          <span>
                            {`Total credits consumed in the current billing cycle. Credits are applied exclusively to LLM usage, with varying consumption rates depending on the specific LLM. `}
                            <a
                              style={{ color: "#fff" }}
                              href="https://help.vextapp.com/en/articles/9418796-what-is-credit-and-how-is-it-calculated"
                              target="blank"
                            >
                              Learn more.
                            </a>
                          </span>
                        }
                      />
                    </p>
                    <p
                      style={{
                        fontStyle: "italic",
                        fontSize: "0.7rem",
                        marginBottom: "1rem",
                      }}
                    >
                      {`${accountInfo.currentCredits?.toLocaleString()} / ${accountInfo.maxCredits?.toLocaleString()} Credits`}
                    </p>
                  </div>
                  <LinearProgress count={(accountInfo.currentCredits / accountInfo.maxCredits) * 100} />
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ marginBottom: "1rem" }}>
                      Data Storage
                      <BasicTooltip tooltip="The total amount of data storage used within this billing cycle. This is calculated across all of your AI projects/data." />
                    </p>
                    <p
                      style={{
                        fontStyle: "italic",
                        fontSize: "0.7rem",
                        marginBottom: "1rem",
                      }}
                    >
                      {`${accountInfo.currentStorage?.toLocaleString()} GB / ${accountInfo.maxStorage?.toLocaleString()} GB`}
                    </p>
                  </div>
                  <LinearProgress count={(accountInfo.currentStorage / accountInfo.maxStorage) * 100} />
                </div>
              </div>
              {/* <h4>Manage Workspaces</h4>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button variant="contained" onClick={() => toggleDialog("createWorkspace")}>
                  Create Workspace
                </Button>
                <div style={{ alignItems: "center", display: "flex", fontSize: "0.8rem", gap: "0.5rem" }}>
                  <strong>Available Credits:</strong>
                  <span>{availableCredit.toLocaleString()}</span>
                </div>
              </div>
              <div className={AccountTabsStyles.workspaceTableLike}>
                {workspaceList.map((item, index) => (
                  <WorkspaceRow
                    key={index}
                    credit={item.credit_limit}
                    userId={item.id}
                    name={item.name}
                    handleEditCreditLimit={() => toggleDialog("editCreditLimit")}
                    handleRemove={() => onClickRemoveWorkspace(item.id)}
                  />
                ))}
              </div> */}
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className={AccountTabsStyles.tabPanel}>
              <div style={{ marginTop: "1.25rem" }}>
                <Button variant="contained" onClick={() => toggleDialog("addMember")}>
                  Add member
                </Button>
              </div>
              {isLoadingTable ? (
                <div className={AccountTabsStyles.loadingCard}>
                  <Skeleton variant="rounded" animation="wave" height={40} />
                  <Skeleton variant="rounded" animation="wave" height={40} />
                  <Skeleton variant="rounded" animation="wave" height={40} />
                </div>
              ) : (
                <div className={AccountTabsStyles.memberTableLike}>
                  {memberList.map((user, index) => (
                    <MemberRow
                      key={index}
                      name={user.name}
                      email={user.email}
                      status={user.status}
                      userId={user.id}
                      role={user.role_id}
                      roleOptions={roleOptions}
                      handleReinvite={handleReinvite}
                      handleRemoveUser={() => onClickRemoveUser(user.id)}
                    />
                  ))}
                </div>
              )}
            </div>
          </TabPanel>
          <ConfirmationDialog
            open={dialogState.show && dialogState.type === "addMember"}
            handlePrimary={handleCreateMember}
            handleSecondary={toggleDialog}
            title="Add Member"
            content={<CreateMemberForm options={roleOptions} onChange={handleFormChange} />}
            primaryButtonText={isLocalLoading ? "Adding..." : "Add member"}
            primaryButtonVariant="contained"
            secondaryButtonVariant="outlined"
            primaryButtonDisabled={isLocalLoading || isCreatingButtonDisabled}
          />
          <ConfirmationDialog
            open={dialogState.show && dialogState.type === "removeMember"}
            handlePrimary={() => handleDeleteUser()}
            handleSecondary={toggleDialog}
            title="Confirm Remove"
            content={
              <>
                <div>Are you sure you want to remove this member?</div>
                <div>This action cannot be undone.</div>
              </>
            }
            primaryButtonColor="error"
            primaryButtonText={isLocalLoading ? "Removing..." : "Remove"}
            primaryButtonDisabled={isLocalLoading}
          />
          <ConfirmationDialog
            open={dialogState.show && dialogState.type === "removeWorkspace"}
            handlePrimary={() => handleDeleteUser()}
            handleSecondary={toggleDialog}
            title="Confirm Delete"
            content={
              <>
                <div>Are you sure you want to delete this workspace?</div>
                <div>This action cannot be undone.</div>
              </>
            }
            primaryButtonColor="error"
            primaryButtonText={isLocalLoading ? "Deleting..." : "Delete"}
            primaryButtonDisabled={isLocalLoading}
          />
          <InputDialog
            title="Create Workspace"
            inputTitle="Workspace Name"
            isLoading={isLocalLoading}
            isOpen={dialogState.show && dialogState.type === "createWorkspace"}
            onClose={toggleDialog}
            onSubmit={handleCreateWorkspace}
            onLoadSubmitButtonText="Creating..."
            submitButtonText="Create"
          />
          <InputDialog
            title="Edit Credit Limit"
            isLoading={isLocalLoading}
            isOpen={dialogState.show && dialogState.type === "editCreditLimit"}
            onClose={toggleDialog}
            onSubmit={handleEditCreditLimit}
            onLoadSubmitButtonText="Saving..."
            submitButtonText="Save"
            hideEndAdornment
          />
        </Box>
      )}
    </>
  )
}
